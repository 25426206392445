import React, { useState, useEffect } from 'react';
import Helmet from 'react-helmet';
import { ThemeProvider } from 'styled-components';
import Sticky from 'react-stickynode';
import { hostingTheme } from 'common/src/theme/hosting';
import axios from 'axios';
import {
  GlobalStyle,
  ContentWrapper,
} from '../../../containers/Hosting/hosting.style';
import { ResetCSS } from 'common/src/assets/css/style';
import Navbar from '../../../containers/Hosting/Navbar';

import Footer from '../../../containers/Hosting/Footer';
import { DrawerProvider } from 'common/src/contexts/DrawerContext';
import { ParallaxProvider } from 'react-scroll-parallax';
import SEO from '../../../components/seo';
import TopRibbon from '../../../containers/Hosting/TopRibbon';

import PropTypes from 'prop-types';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Container from 'common/src/components/UI/Container';
import Section1Wrapper from './section1Wrapper';
import Section2Wrapper from './section2Wrapper';
import ContactMongo from '../../../containers/Hosting/ContactMongo/index';

import Raguceo from 'common/src/assets/image/hosting/ragu-ceo.png';
import bannerbg from 'common/src/assets/image/hosting/bg-1.png';
import bgcard from 'common/src/assets/image/hosting/bg-card.png';
import down from 'common/src/assets/image/hosting/down.png';
import dollar from 'common/src/assets/image/hosting/dollar.png';
import searchicon from 'common/src/assets/image/hosting/search-icon.png';

import { flex, fontSize } from 'styled-system';
import ReCAPTCHA from 'react-google-recaptcha';
const Webinarpage = ({
  row,
  col,
  margintop,
  title,
  subtitle,
  sec2_title,
  whitetitle,
  sec2row,
  name,
  formname,
  sec2col1,
  sec2col2,
  decoration,
}) => {
  const [url, setUrl] = useState('');
  const [success, setSuccess] = useState(false);

  const initialValues = {
    website: 'rapidautomation.ai',
    form_name: formname,
    first_name: '',
    last_name: '',
    email: '',
    role: '',
    company: '',
    contact_number: '',
  };
  const [serverState, setServerState] = useState({
    submitting: false,
    status: null,
  });
  const [Tosend, setTosend] = useState(initialValues);

  const handleInputChange = e => {
    const { name, value } = e.target;
    setTosend({
      ...Tosend,
      [name]: value,
    });
  };

  const handleServerResponse = (ok, msg, form) => {
    setServerState({
      submitting: false,
      status: { ok, msg },
    });
    if (ok) {
      // form.reset();
      // if (forItem === 'case-study') {
      //   navigate(`${pathName.replace('case-study', 'thank-you')}`);
      // } else if (isredirect) {
      //   navigate(`${pathName}-thanks`);
      // }
    }
  };
  function onChange(value) {
    console.log('Captcha value:', value);
  }
  const handleOnSubmit = e => {
    e.preventDefault();
    let formServer = {
      name: Tosend.first_name,
      email: Tosend.email,
      contact_number: Tosend.contact_number,
    };
    const form = e.target;
    setServerState({ submitting: true });
    axios({
      method: 'post',
      url: 'https://forms.rapidautomation.ai/api/saveform',
      data: formServer,
    })
      .then(r => {
        console.log(r);
        setSuccess(true);
        if (r.data.error) {
          handleServerResponse(false, r.data.error, form);
          return;
        }
        if ((r.data.success = 'submission'))
          handleServerResponse(true, "Thank you! We'll reach out soon!", form);
        else {
          handleServerResponse(
            false,
            'Oops! the connection has been interrupted. Please try to submit again.',
            form
          );
        }
      })
      .catch(r => {
        handleServerResponse(
          false,
          'Oops! the connection has been interrupted. Please try to submit again.',
          form
        );
      });
  };
  useEffect(() => {
    const loc = window.location.pathname;
    if (loc.substr(loc.length - 1) === '/') {
      setUrl(loc.substring(0, loc.length - 1));
    } else {
      setUrl(loc);
    }
  }, []);
  return (
    <ThemeProvider theme={hostingTheme}>
      <ParallaxProvider>
        {/* <SEO
          title="Webinar"
          description="Rapid Acceleration Partners provides practical AI and Intelligent RPA solutions. Extract structured data from unstructured content. Book a demo here."
        /> */}
        <Helmet>
          <title>
            Eliminating Chargeback Losses How Intelligent Process Automation Can
            Arrest The Leak
          </title>
          <meta
            name="description"
            content="Eliminating Chargeback Losses
            How Intelligent Process Automation Can Arrest The Leak"
          />
        </Helmet>
        <ResetCSS />
        <GlobalStyle />
        <ContentWrapper className="HomePage">
          <TopRibbon />
          <Sticky
            top={0}
            innerZ={9999}
            activeClass="sticky-nav-active"
            releasedClass="sticky-nav-inactive"
          >
            <DrawerProvider>
              <Navbar />
            </DrawerProvider>
          </Sticky>

          {/*------------------------ 
                  TOPIC TO DISPLAY ON TOP 
              --------------------------*/}

          {/*------------------------ 
                  SPLIT SECTION FOR FORMS 
              --------------------------*/}
          <div
            className="ht45"
            style={{
              backgroundImage: `url(${bannerbg})`,
              width: '100%',
              backgroundRepeat: 'no-repeat',
              marginTop: '6rem',
              backgroundSize: 'cover',
            }}
          >
            <div className="left1 top10r" style={{ position: 'relative' }}>
              <p
                className="pd14 webred"
                style={{
                  fontSize: '21px',
                  color: 'red',
                  fontFamily: 'Inter',
                  lineHeight: '1',
                  fontWeight: '600',
                }}
              >
                Online Webinar
              </p>
              <p
                className="lh1 pd14 mat2 web2 mt35"
                style={{
                  fontSize: '48px',
                  color: 'black',
                  fontWeight: '900',
                  marginTop: '40px',
                }}
              >
                Boost Chargeback Wins with IPA
              </p>
              <p
                className="widbaner mat2 web3  mt50"
                style={{ fontSize: '25px' }}
              >
                Struggling to win chargebacks and reduce revenue losses in the
                hospitality industry?
              </p>
              <p
                className="widbaner web4 mt35"
                style={{ fontSize: '19px', lineHeight: '30px' }}
              >
                The chargeback process in the hospitality industry can be
                chaotic and dispersed, making it difficult for accounting
                leaders to keep track of chargebacks and respond on time. This
                can lead to reduced win rates and ongoing revenue losses.
              </p>
              <p
                className="widbaner web4 mt35"
                style={{ fontSize: '19px', lineHeight: '30px' }}
              >
                By applying IPA to chargeback management , hospitality leaders
                can gain visibility and transparency into the process to make
                informed decisions.{' '}
              </p>
              <p className="widbaner web4 mt35" style={{ fontSize: '19px' }}>
                Say goodbye to disparate processes and hello to increased win
                rates
              </p>
            </div>
          </div>

          <Container>
            <div
              className="flex-containerrmob mt4r  gap0"
              style={{
                fontFamily: 'Inter',
                background: '#fff',
                justifyContent: 'center',
                gap: '3.5rem',
              }}
            >
              <div className="mt10r ">
                <b
                  className="web5"
                  style={{ marginBottom: '4rem', fontSize: '25px' }}
                >
                  Here's what IPA can do for you:
                </b>

                <div
                  className="mb0r"
                  style={{
                    gap: '1rem',
                    marginTop: '2rem',
                    marginBottom: '2rem',
                    display: 'flex',
                  }}
                >
                  <div style={{}}>
                    <img src={dollar} className="ickonwidh" />
                  </div>
                  <div>
                    <p
                      className="web6"
                      style={{
                        color: 'black',
                        fontSize: '20px',
                        marginBottom: '0',
                      }}
                    >
                      <b>Standardize the chargeback process:</b>
                    </p>
                    <p
                      className="width20r web7"
                      style={{ color: 'grey', lineHeight: '27px' }}
                    >
                      {' '}
                      IPA automates the chargeback process, making it more
                      efficient by eliminating manual errors and reducing
                      response time.
                    </p>
                  </div>
                </div>
                <div
                  className="mb0r"
                  style={{ gap: '1rem', marginBottom: '2rem', display: 'flex' }}
                >
                  <div style={{}}>
                    <img src={searchicon} className="ickonwidh" />
                  </div>
                  <div>
                    <p
                      className="web6"
                      style={{
                        color: 'black',
                        fontSize: '20px',
                        marginBottom: '0',
                      }}
                    >
                      <b>Gain visibility and transparency:</b>
                    </p>
                    <p
                      className="width20r web7"
                      style={{ color: 'grey', lineHeight: '27px' }}
                    >
                      {' '}
                      With IPA, you'll have a clear view of all chargeback
                      activity, giving you the power to make informed decisions
                      and increase your win rates.
                    </p>
                  </div>
                </div>

                <div
                  className="mb0r"
                  style={{ gap: '1rem', marginBottom: '2rem', display: 'flex' }}
                >
                  <div style={{}}>
                    <img src={down} className="ickonwidh" />
                  </div>
                  <div style={{}}>
                    <p
                      className="web6"
                      style={{
                        color: 'black',
                        fontSize: '20px',
                        marginBottom: '0',
                      }}
                    >
                      <b>Reduce revenue losses:</b>
                    </p>
                    <p
                      className="width20r web7"
                      style={{ color: 'grey', lineHeight: '27px' }}
                    >
                      {' '}
                      By standardizing the chargeback process and gaining
                      visibility and transparency, you'll be able to reduce
                      revenue losses and increase your win rates
                    </p>
                  </div>
                </div>
              </div>

              {success ? (
                <div className="card1 desk-show">
                  <h1>
                    Thanks for registering! We’ve reserved your space — see you
                    there.
                  </h1>
                </div>
              ) : (
                <div
                  className="mt10rem bgred desk-show"
                  style={{
                    backgroundImage: `url(${bgcard})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'contain',
                  }}
                >
                  <div className="pad5" style={{ color: '#fff' }}>
                    <h1
                      style={{
                        textAlign: 'center',
                        top: '-20px',
                        fontSize: '30px',
                        fontWeight: '900',

                        position: 'relative',
                      }}
                    >
                      {' '}
                      Register Now
                    </h1>

                    <div className="mt32">
                      <form onSubmit={handleOnSubmit}>
                        <div
                          className="flex-containerr"
                          style={{ gap: '1rem', marginBottom: '20px' }}
                        >
                          <input
                            className="width30"
                            style={{
                              height: '3rem',
                              padding: '1rem',
                              borderRadius: '5px',
                              border: 'none',
                              fontSize: '13px',
                            }}
                            type="text"
                            name="first_name"
                            value={Tosend.first_name}
                            onChange={handleInputChange}
                            placeholder="Name*"
                            required
                          />

                          <input
                            className="width30"
                            style={{
                              height: '3rem',
                              padding: '1rem',
                              borderRadius: '5px',
                              border: 'none',
                              fontSize: '13px',
                            }}
                            type="email*"
                            name="email"
                            value={Tosend.email}
                            onChange={handleInputChange}
                            placeholder="Work Email*"
                            required
                          />
                        </div>
                        <div>
                          <input
                            className="width13r"
                            style={{
                              height: '3rem',
                              padding: '1rem',
                              borderRadius: '5px',
                              border: 'none',
                              fontSize: '13px',
                              marginBottom: '1rem',
                            }}
                            type="number*"
                            name="contact_number"
                            value={Tosend.contact_number}
                            onChange={handleInputChange}
                            placeholder="Work Number*"
                            required
                          />
                        </div>

                        {/* <ReCAPTCHA sitekey="6LfcCickAAAAABBoJyO9fixibYiLhv6QpiZDdr10" /> */}
                        <button
                          className="width13r"
                          style={{
                            height: '3rem',
                            marginTop: '1rem',
                            padding: '1rem',
                            background: '#e72131',
                            color: 'WHITE',
                            borderRadius: '5px',
                            fontSize: '16px',
                            border: 'none',
                          }}
                        >
                          Register
                        </button>
                      </form>
                    </div>
                    <p
                      className="mt20"
                      style={{
                        textAlign: 'center',
                        fontSize: '20px',
                        lineHeight: '23px',
                        marginTop: '40px',
                      }}
                    >
                      {' '}
                      Don't miss this opportunity to learn how IPA can help you
                      boost your chargeback wins.{' '}
                    </p>
                    <p
                      style={{
                        textAlign: 'center',
                        fontSize: '20px',
                        lineHeight: '23px',
                        marginBottom: '50px',
                      }}
                    >
                      Register for the webinar today and join us for a live demo
                      of IPA in action.
                    </p>
                    <p
                      className="mt42 f21"
                      style={{
                        textAlign: 'center',
                        fontSize: '26px',
                        lineHeight: '23px',
                        marginBottom: '-10px',
                        fontWeight: '900',
                      }}
                    >
                      WEDNESDAY
                    </p>
                    <p
                      className="f21"
                      style={{
                        textAlign: 'center',
                        fontSize: '26px',
                        marginBottom: '34px',
                        fontWeight: '900',
                      }}
                    >
                      FEBRAURY 22 2023 | 9AM PST
                    </p>
                  </div>
                </div>
              )}

              {success ? (
                <div className="card1 mobshow">
                  <h1>
                    Thanks for registering! We’ve reserved your space — see you
                    there.
                  </h1>
                </div>
              ) : (
                <div
                  className="mt10rem bgred mobshow"
                  style={{
                    backgroundImage: 'linear-gradient(#53b8b7, #363e47)',
                  }}
                >
                  <div className="pad5" style={{ color: '#fff' }}>
                    <h1
                      style={{
                        textAlign: 'center',
                        top: '-20px',
                        fontSize: '30px',
                        fontWeight: '900',

                        position: 'relative',
                      }}
                    >
                      {' '}
                      Register Now
                    </h1>

                    <div>
                      <form onSubmit={handleOnSubmit}>
                        <div
                          className="flex-containerr"
                          style={{ gap: '1rem', marginBottom: '20px' }}
                        >
                          <input
                            className="width30"
                            style={{
                              height: '3rem',
                              padding: '1rem',
                              borderRadius: '5px',
                              border: 'none',
                              fontSize: '13px',
                            }}
                            type="text"
                            name="first_name"
                            value={Tosend.first_name}
                            onChange={handleInputChange}
                            placeholder="Name*"
                            required
                          />

                          <input
                            className="width30"
                            style={{
                              height: '3rem',
                              padding: '1rem',
                              borderRadius: '5px',
                              border: 'none',
                              fontSize: '13px',
                            }}
                            type="email*"
                            name="email"
                            value={Tosend.email}
                            onChange={handleInputChange}
                            placeholder="Work Email*"
                            required
                          />
                        </div>
                        <div>
                          <input
                            className="width13r"
                            style={{
                              height: '3rem',
                              padding: '1rem',
                              borderRadius: '5px',
                              border: 'none',
                              fontSize: '13px',
                              marginBottom: '1rem',
                            }}
                            type="number*"
                            name="contact_number"
                            value={Tosend.contact_number}
                            onChange={handleInputChange}
                            placeholder="Work Number*"
                            required
                          />
                        </div>
                        <div
                          style={{
                            width: '1rem',
                            transform: 'scale(0.85)',
                            transformOrigin: '0 0',
                          }}
                        >
                          {/* <ReCAPTCHA sitekey="6LfcCickAAAAABBoJyO9fixibYiLhv6QpiZDdr10" /> */}
                        </div>
                        <button
                          className="width13r"
                          style={{
                            height: '3rem',
                            marginTop: '1rem',
                            padding: '1rem',
                            background: '#e72131',
                            color: 'WHITE',
                            borderRadius: '5px',
                            fontSize: '16px',
                            border: 'none',
                          }}
                        >
                          Register
                        </button>
                      </form>
                    </div>
                    <p
                      style={{
                        textAlign: 'center',
                        fontSize: '20px',
                        lineHeight: '23px',
                        marginTop: '40px',
                      }}
                    >
                      {' '}
                      Don't miss this opportunity to learn how IPA can help you
                      boost your chargeback wins.{' '}
                    </p>
                    <p
                      style={{
                        textAlign: 'center',
                        fontSize: '20px',
                        lineHeight: '23px',
                        marginBottom: '70px',
                      }}
                    >
                      Register for the webinar today and join us for a live demo
                      of IPA in action.
                    </p>
                    <p
                      style={{
                        textAlign: 'center',
                        fontSize: '26px',
                        lineHeight: '23px',
                        marginBottom: '-10px',
                        fontWeight: '900',
                      }}
                    >
                      WEDNESDAY
                    </p>
                    <p
                      style={{
                        textAlign: 'center',
                        fontSize: '26px',
                        marginBottom: '34px',
                        fontWeight: '900',
                      }}
                    >
                      FEBRAURY 22 2023 | 9AM PST
                    </p>
                  </div>
                </div>
              )}
            </div>

            <div
              className="flex-containerr mlf10"
              style={{
                fontFamily: 'Inter',
                justifyContent: 'center',
                gap: '2rem',
                marginTop: '2rem',
              }}
            >
              <div>
                <img className="wid23" src={Raguceo} />
              </div>
              <div>
                <p
                  className="mtd7"
                  style={{ color: '#ff3442', fontSize: '15px' }}
                >
                  <b>Speaker</b>
                </p>
                <b style={{ color: 'black', fontSize: '25px' }}>
                  Raghuraman Ramamurthy
                </b>
                <p
                  style={{
                    color: '#646464',
                    fontSize: '17px',
                    marginTop: '10px',
                  }}
                >
                  CEO
                </p>
                <p style={{ color: '#646464', marginTop: '-10px' }}>
                  Rapid Acceleration Partners
                </p>
                <p
                  style={{
                    color: '#646464',
                    fontSize: '16px',
                    lineHeight: '23px',
                  }}
                >
                  Parallel Entrepreneur and Business Leader with proven results
                  in delivering significant business value through end-to-end
                  digital transformation and automation.
                </p>

                <p
                  style={{
                    color: '#646464',
                    fontSize: '16px',
                    lineHeight: '23px',
                  }}
                >
                  On a mission to democratise Intelligent Process Automation by
                  making it easier to access and affordable and help enterprises
                  automate processes end to end.
                </p>
                <p
                  style={{
                    color: '#646464',
                    fontSize: '16px',
                    lineHeight: '23px',
                  }}
                >
                  Helping customers across BFSI, Hospitality, Automotive,
                  Manufacturing, Shared Services with end to end automation
                  helping save upto 70% manual effort.
                </p>
              </div>
            </div>
          </Container>

          <Footer />
        </ContentWrapper>
      </ParallaxProvider>
    </ThemeProvider>
  );
};
Webinarpage.propTypes = {
  row: PropTypes.object,
};

Webinarpage.defaultProps = {
  margintop: {
    mt: '100px',
  },

  decoration: {
    backgroundColor: '#424996',
    py: '50px',
  },
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    justifyContent: 'center',
    position: 'relative',
  },
  sec2row: {
    flexBox: true,
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },
  sec2col1: {
    flexBox: true,
    flexWrap: 'nowrap',
    width: ['100%', '100%', '100%', '48%', '48%'],
    mr: ['0', '0', '0', '2%', '2%'],
    justifyContent: [
      'flex-start',
      'flex-start',
      'flex-start',
      'flex-end',
      'flex-end',
    ],
  },
  sec2col2: {
    flexBox: true,
    flexWrap: 'nowrap',
    width: ['100%', '100%', '100%', '48%', '48%'],
    ml: ['0', '0', '0', '2%', '2%'],
    justifyContent: [
      'flex-end',
      'flex-end',
      'flex-end',
      'flex-start',
      'flex-start',
    ],
  },
  col: {
    width: ['100%', '100%', '100%', '48%', '48%'],
    mr: ['0', '0', '0', '1%', '1%'],
    ml: ['0', '0', '0', '1%', '1%'],
    pt: ['10px', '10px', '75px', '75px', '75px'],
    position: 'relative',
  },

  title: {
    as: 'h1',
    fontSize: ['32px', '36px', '38px', '38px', '40px'],
    fontWeight: '500',
    // color: '#0f2137',
    // color: "#fff",
    color: '#fff',
    letterSpacing: '0.025em',
    mb: 0,
    lineHeight: '1.31',
    textAlign: 'center',
  },
  subtitle: {
    as: 'h2',
    fontSize: ['20px', '20px', '20px', '20px', '20px'],
    fontWeight: '500',
    color: '#d9d9d9',
    letterSpacing: '0.025em',
    mt: '20px',
    mb: 0,
    lineHeight: '1.31',
    textAlign: 'center',
  },
  sec2_title: {
    as: 'h3',
    fontSize: ['20px', '24px', '24px', '26px', '26px'],
    fontWeight: '700',
    color: '#202342',
    letterSpacing: '0.025em',
    mb: 0,
    lineHeight: '1.31',
    textAlign: 'left',
  },
  whitetitle: {
    as: 'h3',
    fontSize: ['26px', '28px', '30px', '30px', '30px'],
    fontWeight: '500',
    color: '#fff',
    letterSpacing: '0.025em',
    mb: '40px',
    lineHeight: '1.31',
    mr: '28px',
    textAlign: 'center',
  },
};
export default Webinarpage;
